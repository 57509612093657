import { GiCheckMark } from "react-icons/gi";

import asesor from "../img/3.png"
import camioncito from "../img/2.png"

const Asesorias=()=>{
    return(
        <section className="mb-5 " >
            <div className="w-100 mb-5 linea_azul"> </div>
            <div className="container-mt mb-5" >
            <div className=" w-mt-75 w-100">
               <div className="d-flex gap-3 align-items-center mb-3">
                        <img src={asesor} alt="asesor" className=" w-25 h-25"/>
                        <h1 className=" fw-bold mt-aseso ">Asesorías</h1>
                </div>
                <div className="d-flex gap-2">
                
                    <GiCheckMark size={100} />
                    <p className="m-0 mt-p">
                    Asesorías en temas de comercio internacionales, desde antes de la ​compra hasta la entrega de las mercancías en las bodegas del ​importador o exportador. Asimismo prevenimos posibles ajustes ​realizados por la Superintendencia de Administración Tributaria <strong>​SAT</strong>  en materia de comercio exterior.
                    </p>
                
                </div>
               </div>
            </div>

            <div className="container-mt mb-5 d-flex mt-justify-end" >
                <div className=" w-mt-75 w-100">
                <div className="d-flex gap-3 align-items-center mb-3 ">
                        <img src={camioncito} alt="camioncito" className=" w-25 h-25"/>
                        <h1 className=" fw-bold mt-aseso">Transporte</h1>
                </div>
                <div className="d-flex gap-2">
                
                    <GiCheckMark size={100} />
                    <p className="m-0 mt-p">
                    Transporte terrestre, contamos con los <strong>permisos</strong> de las autoridades aduanera para brindar el servicio de transporte aduanero. Nuestras ​unidades tienen diferentes capacidades, lo que nos permite atender el ​transporte terrestre a nivel local y nacional. Las unidades son ​supervisadas para cumplir con nuestros estándares de calidad y ​seguridad. <strong>Estamos en capacidad de proporcionar transporte ​terrestre.</strong>
                    </p>
                </div>
                </div>
            </div>

            
            <div className="w-100 linea_azul"> </div>       
        </section>
    );
}

export default Asesorias