import trailer from "../img/12.png";
import barco from "../img/13.png";
import avion from "../img/14.png";
import trailerf from "../img/11.png";
import barcof from "../img/10.png";
import avionf from "../img/9.png";
import {useState } from "react";
const Tarjetas = () => {
    const [plane,setPlane]=useState(false)
    const [ship,setShip]=useState(false)
    const [truk,setTruk]=useState(false)
    

    const handleplane = ()=>{setPlane(!plane)}
    const handleship = ()=>{setShip(!ship)}
    const handletruk = ()=>{setTruk(!truk)}

    


    return (
        <section className="mb-5 container-mt " >
            <div className=" d-flex gap-4">
                <div onMouseEnter={handletruk} onMouseLeave={handletruk} className="position-relative cont-img d-flex justify-content-center align-items-center">
                <figure className="d-flex justify-content-center " >
                        <img src={trailer} alt="trailer" className=" w-50 h-50 mt-4" />
                    </figure>
                    <figure className={`position-absolute z-n1 top-0 fondo-tarjeta ${truk?"fade-in":"d-none"}`} >
                        <img src={trailerf} alt="trailer" className=" w-100 h-100" />
                    </figure>
                </div>
                <div onMouseEnter={handleship} onMouseLeave={handleship} className="position-relative cont-img d-flex justify-content-center align-items-center ">
                    <figure className="d-flex justify-content-center " >
                        <img src={barco} alt="trailer" className=" w-50 h-50 mt-4" />
                    </figure>
                    <figure className={`position-absolute z-n1 top-0 fondo-tarjeta ${ship?"fade-in":"d-none"}`}>
                        <img src={barcof} alt="trailer" className=" w-100 h-100" />
                    </figure>
                </div>
                <div onMouseEnter={handleplane} onMouseLeave={handleplane} className="position-relative cont-img d-flex justify-content-center align-items-center">
                <figure className="d-flex justify-content-center" >
                        <img src={avion} alt="trailer" className=" w-50 h-50 mt-4" />
                    </figure>
                    <figure className={`position-absolute z-n1 top-0 fondo-tarjeta ${plane?"fade-in":"d-none"}`}>
                        <img src={avionf} alt="trailer" className=" w-100 h-100" />
                    </figure>
                </div>

            </div>



        </section>
    );
}

export default Tarjetas