import { GoDotFill } from "react-icons/go";

const Footer=()=>{
    return(
        <section className="mb-5 container-mt " >
            <div className=" d-flex flex-wrap no-wrap gap-5">
            <div className="w-100 w-mt-50">
                <h2 className="fw-bold rounded-pill py-3 px-3 titulo-nosotros text-white text-center mt-h2"> Servicios</h2>
                <div className="d-flex gap-2 align-items-center mb-2" >
                <GoDotFill /> 
                <p className="m-0 mt-p">Gestión Aduanera </p>
                </div>
                <div className="d-flex gap-2 align-items-center mb-2" >
                <GoDotFill /> 
                <p className="m-0 mt-p"> Transporte</p>
                </div>
                <div className="d-flex gap-2 align-items-center mb-2" >
                <GoDotFill /> 
                <p className="m-0 mt-p"> Recolección de Documentos</p>
                </div>
                <div className="d-flex gap-2 align-items-center mb-2" >
                <GoDotFill /> 
                <p className="m-0 mt-p">Gestiones de licencia de importación </p>
                </div>
                
            </div>
            <div className="w-100 w-mt-50">
                <h2 className="fw-bold rounded-pill py-3 px-3 titulo-nosotros text-white text-center mt-h2"> Contáctanos</h2>
                <div className="mb-3" >
                <h3 className="fw-bold m-0 mt-h4">Ubicación </h3>
                <div className="d-flex gap-2 align-items-center mb-2" >
                <GoDotFill /> 
                <p className="m-0 mt-p">17 calle 7-80 Zona 13 Colonia Aurora </p>
                </div>
                <div className="d-flex gap-2 align-items-center mb-2" >
                <GoDotFill /> 
                <p className="m-0 mt-p">9a avenida 15-84A aurora 1 zona 13</p>
                </div>
                </div>
                <div className="mb-2" >
                <h3 className="fw-bold m-0 mt-h4"> Ventas</h3>
                <p className="m-0 mt-p "> Tel: <a href="tel:+50230156817">3015-6817</a>,<a href="tel:+502​56976272">​5697-6272</a></p>
                </div>
                
                
            </div>
            </div>

            
            
                
        </section>
    );
}

export default Footer