import img1 from "../img/2o.png";

const Nosotros=()=>{
    return(
        <section className="mb-5 position-relative" >
            
            <figure className="m-0" >
                <img src={img1} alt= "nosotros" className="w-100 h-100"/>
            </figure>

            <div className="position-absolute mt-mt top-0 mt-2 w-100 " >
               <div className="d-flex flex-wrap  container-mt">
               <h4 className="text-uppercase fw-bold rounded-pill py-2 px-2 titulo-nosotros text-white mt-h4 ">
                    quienes somos 
                </h4>
                 <div>
                 <p className="text-justify text-white description-nosotros mb-0 mt-p w-mt-50 " >
                Servicio aduanero y logística integral con experiencia en ​comercio aduanero. Enfocados en soluciones ​específicas para importaciones y exportaciones, ​basados en principios de honradez, honestidad y ​responsabilidad. Infraestructura logística y recursos ​tecnológicos, cada uno de ellos orientados a brindar el ​servicio aduanero que nuestros clientes requieren y ​necesitan, apegándonos a tiempos de entrega y ​maximizando recursos.
                </p>
                </div>
               </div>
                
            </div>
            <div className="w-100 linea_azul"> </div>

                
        </section>
    );
}

export default Nosotros