const Portadas=({img})=>{
    return(
        <section>
            <figure className="m-0">
                <img src={img} alt="portada" className="w-100 h-100"/>
            </figure>
        </section>
    );
}

export default Portadas