
import './App.css';
import Documentos from './components/Documentos';
import Nosotros from './components/Nosotros';
import Portadas from './components/Portadas';
import Titulo from './components/Titulo';
import inicio from "./img/inicio.png";
import avion from "./img/3o.png";
import Objetivos from './components/Objetivos';
import Servicios from './components/Servicios';
import trabajador from './img/4o.png';
import Asesorias from './components/Asesorias';
import Tarjetas from './components/Tarjetas';
import Footer from './components/Footer';
import Mision from './components/Mision';

function App() {
  return (
      <>
      <Portadas img={inicio}/>
      <Titulo/>
      <Nosotros/>
      <Documentos/>
      <Objetivos/>
      <Portadas img={avion}/>
      <Mision/>
      <Servicios/>
      <Portadas img={trabajador}/>
      <Asesorias/>
      <Tarjetas/>
      <Footer/>
      </>
  );
}

export default App;
