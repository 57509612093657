

const Objetivos=()=>{
    return(
        <section className="mb-2 container-mt " >
            <h2 className="fw-bold mt-h2">Nuestros objetivos:</h2>
            <p className="text-justify mt-p">Contribuir al desarrollo y crecimiento de la Organización ​siendo parte de un gran equipo de profesionales mostrando ​ser personas responsables, dedicadas, honestas, organizadas, ​colaboradoras y con muchos deseos de superación tanto ​profesionales como personales</p>
            
                
        </section>
    );
}

export default Objetivos