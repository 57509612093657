import decorador from "../img/1.png"
import bandera from "../img/16.png"
import flecha from "../img/15.png"
const Mision = () => {

    return (
        <section className="my-5 " >
            <div className="position-relative mb-5 d-flex align-items-center justify-content-center avionv">
                <figure className="position-absolute top-0 start-0 correra ">
                    <img src={decorador} alt="decorador" className="w-25 h-25 imagen-avion avionmovil" />
                </figure>
                <h2 className="fw-bold text-center mt-h1-8">Misión y Visión</h2>

            </div>

            <div className=" mb-5  container-mt  ">
                <div className="w-mt-75  fondo-medio rounded-4 pe-3 d-flex gap-3 w-100 alturafija" >
                    <div className="fondo-fuerte p-3 d-flex align-items-center justify-content-center">
                        <figure>
                            <img src={bandera} alt="bnadera" className="w-75 h-75 hombrecentro" />
                        </figure>
                    </div>
                    <div className=" my-2">
                        <h2 className="fw-bold text-start text-white mt-h2 text-shadow-drop-center" > VISIÓN</h2>
                        <p className="m-0 mt-p description-nosotros">Ser una empresa con vocación de servicio, ​responsabilidad, eficiente y ágil que constituya ​una herramienta de éxito y satisfacción para ​nuestros clientes.</p>
                    </div>
                </div>
            </div>
            <div className=" container-mt mb-5 mt-justify-end d-flex">
                <div className="w-mt-75 fondo-medio rounded-4 gap-3 d-flex ps-3 w-100 alturafija" >
                <div className="my-2" >
                    <h2 className="fw-bold text-start text-white mt-h2 text-shadow-drop-center" > MISIÓN</h2>
                    <p className="m-0 mt-p description-nosotros">Realizar nuestro trabajo con prontitud ​utilizando lo mejor de nuestro personal ​humano, como tecnológico, ofreciendo de ​esta forma la mejor de las opciones de ​servicio en el ramo de gestión aduanal.</p>
                </div>
                <div className=" fondo-fuerte  p-3 d-flex align-items-center justify-content-center">
                    <figure>
                        <img src={flecha} alt="bnadera" className="w-75 h-75 flechacentro flechamision" />
                    </figure>
                </div>
                </div>

            </div>





        </section>

    );

}

export default Mision