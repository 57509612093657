const Titulo=()=>{
    return(
        <section className="my-5 position-relative" >
            <div className=" position-absolute top-0 strat-0">
                <figure>

                </figure>
            </div>
        <h3 className="text-center mt-h4 texto-m w-100 "> AGENCIA PROFESIONAL EN ADUANA</h3>
        <h1 className="text-uppercase fw-bold text-center mt-h1 espacios" >
            amt customs services 
        </h1>
        <h2 className=" text-center mt-h2" >
            Importaciones y Exportaciones
        </h2>
            
        </section>
    );
}

export default Titulo