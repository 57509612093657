import { FiDownload } from "react-icons/fi";

const Documentos=()=>{
    return(
        <section className="mb-5 container-mt " >
            <div className="d-flex flex-wrap no-wrap gap-3"> 
            <a href="https://portal.sat.gob.gt/portal/recursos-aduaneros/" rel="noreferrer"  target="_blank" className="w-100 w-mt-50">
                <div className="d-flex w-100 p-3 rounded-pill docu text-white justify-content-center align-items-center gap-3"> 
                    <h2 className="mt-h2" > Cauca</h2>
                    <span><FiDownload size={40}/></span>
                    
                </div>
            </a>
            <a href="https://portal.sat.gob.gt/portal/recursos-aduaneros/" rel="noreferrer" target="_blank" className="w-100 w-mt-50">
                <div className="d-flex w-100 p-3 rounded-pill docu text-white justify-content-center align-items-center gap-3">
                    <h2 className="mt-h2" > Recauca</h2>
                    <span><FiDownload size={40}/></span>
                </div>
            </a>

            </div>
            
            
                
        </section>
    );
}

export default Documentos