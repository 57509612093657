import { GiCheckMark } from "react-icons/gi";
import hoja from "../img/8.png"
import carnet from "../img/4.png"
import folder from "../img/5.png"
import camion from "../img/6.png"
import manos from "../img/7.png"
import { useRef, useState,useEffect } from "react";

const Servicios=()=>{
    const [isVisible,setIsVisible]=useState(false)
    const sectionRef = useRef(null)
     useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setIsVisible(true);
              } else {
                setIsVisible(false);
              }
            });
          },
          {
            threshold: 0.1, // Ajusta el umbral según necesites
          }
        );
        const current= sectionRef.current
        if (current) {
          observer.observe(current);
        }
    
        return () => {
          if (current) {
            observer.unobserve(current);
          }
        };
      }, [sectionRef]);


    return(
        <section className="my-5  container-mt  " >
            <h2 className="fw-bold text-center  mt-aseso">
            Nuestros Servicios
            </h2>
            <div className="d-flex flex-column mb-5 gap-2">
                <div className="d-flex gap-2 align-items-center">
                <GiCheckMark />
                <p className="m-0 mt-p" >
                Elaboración de Ducas
                </p>

                </div>
                <div className="d-flex gap-2 align-items-center">
                <GiCheckMark />
                <p className="m-0 mt-p" >
                Gestión Aduanera
                </p>
                </div>
                
                <div className="d-flex gap-2 align-items-center">
                <GiCheckMark />
                <p className="m-0 mt-p" >
                Transporte
                </p>
                </div>
                
                <div className="d-flex gap-2 align-items-center">
                <GiCheckMark />
                <p className="m-0 mt-p" >
                Recolección de Documentos
                </p>

                </div>
                
                <div className="d-flex gap-2 align-items-center">
                <GiCheckMark />
                <p className="m-0 mt-p" >
                Gestiones de licencia de importación
                </p>

                </div>
            </div>
            <div ref={sectionRef} className=" d-flex  gap-3">
                <figure className={isVisible?"rotate-center":""}>
                    <img src={hoja} alt="hoja" className=" w-100 h-100"/>
                </figure>
                <figure className={isVisible?"rotate-center":""}>
                    <img src={manos} alt="manos" className=" w-100 h-100"/>
                </figure>
                <figure className={isVisible?"rotate-center":""}>
                    <img src={camion} alt="camion" className=" w-100 h-100"/>
                </figure>
                <figure className={isVisible?"rotate-center":""}>
                    <img src={folder} alt="folder" className=" w-100 h-100"/>
                </figure>
                <figure className={isVisible?"rotate-center":""}>
                    <img src={carnet} alt="carnet" className=" w-100 h-100"/>
                </figure>


            </div>
            
        </section>
    );
}

export default Servicios